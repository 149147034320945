import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

// Series specific actions
import {
  LOAD_SERIES_DETAIL,
  loadSimilarItems,
  LOAD_SIMILAR_SERIES,
  loadSeasons,
  LOAD_SEASON_DATA,
  loadTVShowDetailItems,
  LOAD_SERIES_PAGE_CONTAINERS,
  loadSeasonEntitlements,
  LOAD_SEASON_ENTITLEMENTS,
  LOAD_SERIES_SCHEDULES,
  loadSchedules,
  LOAD_CONTENT_USERDATA,
  loadContentUserData,
  LOAD_LIST_OF_TEAMS,
  LOAD_RECENT_AIRINGS,
  LOAD_LIST_OF_GAMES,
} from "./state/actions";

import {
  resetAction,
  loadUserSubscribedChannels,
  showToastNotification,
  setConvivaContentSubType,
  showModalPopup,
  showRecordingSettingsPanel,
  ADD_FAVOURITE,
  DELETE_FAVOURITE,
  addFavourites,
  deleteFavourites,
  loadChannels,
} from "../../App/state/actions";
import {
  setRecordingAction,
  getRecordingAction,
  toggleSettingsPanelAction,
  SET_RECORDINGS,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
} from "../RecordingsPage/state/actions";
import Wall from "../../components/Wall";
import WallContent from "../../components/WallContent";
import constants from "../../shared/constants/index";
import recordingConstants from "../../shared/constants/recordingConstants";
import Swimlane from "../../components/Swimlane";
import swimlaneConstants from "../../shared/constants/swimlane";
import SwimlaneItem from "../../components/SwimlaneItem";
import CastSwimlaneItem from "../../components/SwimlaneItem/CastSwimlaneItem";
import { useTranslation } from "react-i18next";
import { createSwimlaneFeedItem, createEpisodeSwimlaneItems } from "../../shared/utils/swimlane";
import createSwimlaneModels from "../../shared/utils/dataFilters";
import { getAVSKeyArtImage } from "../../shared/utils/image";
import {
  getSubTextLine1,
  getItemLiveAvailability,
  getLiveChannelAsset,
  getLookbackAvailabilityString,
  getSortedSeasons,
  getSubTextLine2,
} from "../../shared/utils/feedHelper";
import {
  getRecordingCTAs,
  getRecordingInfo,
  setRecording,
  getRecordingSystemType,
  getRecordablePrograms,
  getCPVRRecordingStatus,
  doesCPVRSeriesRecordingExist,
  doesCPVREventRecordingExist,
  navigateToPVRManager,
  checkCPVRConflicts,
  showCPVRConflictModal,
  isRecordingPendingStateCheck,
  getRouteToPVRManager,
  isCPVRRecordingRecorded,
  isCPVRRecordingInProgress,
} from "../../shared/utils/recordingHelper";
import {
  getPcLevelRestriction,
  seasonCompareFunction,
  calculateLookbackHoursLeft,
  getNewestLookbackProgram,
  getAutoGeneratedObject,
} from "../../shared/utils";
import routeConstants from "../../shared/constants/routes";

import "./style.scss";
import SeasonPickerDropDown from "../../components/SeasonPickerDropdown";
import ImageButton from "../../components/ImageButton";
import OptikButton from "../../components/OptikButton";
import SeoPageTags from "../../components/SeoPageTags";
import { handleLogin } from "../../shared/utils/login";
import { trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import { getFirstPlayableEpisode, getPlayableEpisode } from "../../shared/utils/playbackHelpers";
import RecordingModal from "../../components/RecordingModal";
import WaysToWatchPrompt from "../../components/WaysToWatchPrompt";
import { getSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import {
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  ANALYTICS_EVENT_TYPES,
  EXTRA_METADATA_TYPES,
  MAPPED_CONTENT_TYPES,
} from "../../shared/constants/analytics";
import { useConvivaContentSubType } from "../../shared/hooks/useConvivaContentSubType";
import { getAAVideoPlayType } from "../../shared/analytics/helpers";
import { getRegionalChannelNumber, isGhostChannel } from "../../shared/utils/epg";
import { logNREvent, setNRAttribute } from "../../shared/analytics/newRelic";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { mapRecordingSeriesMediaContent } from "../../shared/analytics/helpers";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import EpisodeSwimlaneItem from "../../components/SwimlaneItem/EpisodeSwimlaneItem";
import playerConstants from "../../shared/constants/player";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import useDetailsPageCompositor from "../../shared/hooks/useDetailsPageCompositor";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";

const outHomeIcon = process.env.PUBLIC_URL + "/images/Out_of_home.svg";
const unSubscribedIcon = process.env.PUBLIC_URL + "/images/key-icon.svg";
const playIcon = process.env.PUBLIC_URL + "/images/play-icon.svg";
const favourite_icon_active = process.env.PUBLIC_URL + "images/favourite_active.svg";
const favourite_icon = process.env.PUBLIC_URL + "images/favourite_white.svg";
const seriesInfoIcon = process.env.PUBLIC_URL + "images/ico_cta_circle.svg";

const { IMAGES, CONTENT_ITEM_TYPES, CONTAINER_TYPES, ACTION_KEYS, PAGE_CONTENT_ITEM_TYPES, MODAL_TYPES, LOGIN_BRANDS } =
  constants;
const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;
const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const { SIMILAR_ITEMS, LIVE_PLAYER, ON_DEMAND_PLAYER, SERIES_DETAIL_PAGE } = routeConstants;
const { PLAYBACK_TYPES } = playerConstants;

/**
 * Movie Detail page component
 *
 * @component
 * @param {Object} props
 */

function SeriesDetailPage(props) {
  const {
    userProfile,
    appProvider,
    loadTVShowDetailItems,
    resetAction,
    similarItems,
    loadSimilarItems,
    addFavourites,
    isAddedToFavourites,
    deleteFavourites,
    loadSchedules,
    schedules,
    showToastNotification,
    loadContentUserData,
    contentUserData,
    loadSeasons,
    seasonData,
    loadSeasonEntitlements,
    seasonEntitlements,
    loadUserSubscribedChannels,
    subscribedChannels,
    setRecordingAction,
    getRecordingAction,
    setConvivaContentSubType,
    showRecordingSettingsPanel,
    showModalPopup,
    totalSimilarItems,
    toggleSettingsPanelAction,
    recordingsList,
    getRecordingError,
    setRecordingResponse,
    editRecordingResponse,
    deleteRecordingResponse,
    manipulatedRecordingParams,
    isInHome,
    featureToggles,
    loadChannels,
    channelMapInfo,
    listOfTeams,
    recentAirings,
    listOfLiveGames,
  } = props;
  const {
    isRecordingEnabled,
    isRecommendationMLTEnabled,
    isLookbackEnabled,
    isFavouritesEnabled,
    isUserProfilesEnabled,
    isParentalPINEnabled,
    isConvivaAppTrackerEnabled,
    isKoodoLoginEnabled,
  } = featureToggles;

  const [loadComponent] = useState(true);
  const [priorityEpisode, setPriorityEpisode] = useState(null);
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const liveItem = useRef(0);
  const scheduleItemIndex = useRef(0);
  const [isItemLive, setIsItemLive] = useState(false);
  const [viewAllUrl, setViewAllUrl] = useState("");
  const [currentSeasonIndex, setCurrentSeasonIndex] = useState(null);
  const [showWaysToWatch, setShowWaysToWatch] = useState(false);
  const [svodWatchOptions, setSvodWatchOptions] = useState(null);
  const [filteredRecordings, setFilteredRecordings] = useState([]);

  const match = useRouteMatch();
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const location = useLocation();

  const { performPlaybackChecks } = usePlaybackChecks();
  const {
    params: { uriType, uriSubType, itemType, contentId },
  } = match;
  const isTeamsPage = itemType.toLowerCase() === CONTENT_ITEM_TYPES.team;
  const isSportSeries = itemType === PAGE_CONTENT_ITEM_TYPES.sportsSeries;
  const { contentMetadata, isUnifiedAsset } = useDetailsPageCompositor(
    CONTENT_ITEM_TYPES.series,
    isTeamsPage,
    isSportSeries
  );
  const liveGamesList = [];
  const uaGamesList = {};
  if (isSportSeries && listOfLiveGames?.length > 0) {
    for (let i = 0; i < listOfLiveGames.length; i++) {
      const liveGame = listOfLiveGames[i];
      const airingStartTime = liveGame?.metadata?.airingStartTime;
      const timeStamp = airingStartTime && moment(airingStartTime);
      const timeStampDate = timeStamp?.format("ddd, MMM DD");
      liveGamesList.push({ ...liveGame, timeStampDate });
    }
  }
  for (let i = 0; i < liveGamesList.length; i++) {
    const game = liveGamesList[i];
    const timeStampDate = game.timeStampDate;
    if (uaGamesList[timeStampDate] == null) uaGamesList[timeStampDate] = [];
    uaGamesList[timeStampDate].push(game);
  }
  const modifiedGamesList = Object.keys(uaGamesList).map((timeStampDate) => {
    const currentDate = moment().format("ddd, MMM DD");
    let day;
    if (timeStampDate === currentDate) {
      day = translate("today");
    } else if (timeStampDate === moment().add(1, "d").format("ddd, MMM DD")) {
      day = translate("tomorrow");
    } else if (timeStampDate === moment().subtract(1, "d").format("ddd, MMM DD")) {
      day = translate("yesterday");
    } else {
      day = timeStampDate;
    }
    return {
      metadata: { season: day, episodeCount: uaGamesList[timeStampDate]?.length },
      games: uaGamesList[timeStampDate],
    };
  });
  const airingTodayIndex =
    modifiedGamesList?.length > 0 && modifiedGamesList.findIndex((game) => game.metadata.season === translate("today"));
  const liveUAEpisodes = isSportSeries
    ? modifiedGamesList[currentSeasonIndex]?.games
    : isTeamsPage
    ? listOfLiveGames
    : seasonEntitlements;

  const convivaContSubType = useRef(useConvivaContentSubType(setConvivaContentSubType));
  const query = new URLSearchParams(location.search);
  const seasonIndex = query.get("index") ? query.get("index") : airingTodayIndex ? airingTodayIndex : 0;
  const [liveSchedule, setLiveSchedule] = useState(null);
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const isRecordingActionInProgress = useRef(false);
  const recordingEventTypeRef = useRef(null);
  const recordingEventCallbackFuncRef = useRef(null);
  const areGenreBasedSimilarItemsFetched = useRef(false); // ref to check if call was made to fetch similar items using client(genre-based) logic
  const isLiveProgramOOH = useRef(false); // ref to check for OOH restricted live program for primary(watch live) CTA
  const [entitlementSeason, setEntitlementSeason] = useState(null);
  const isAPIcallFired = useRef(false);

  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();

  const detailUri = `/${uriType}/${uriSubType}/${itemType}/${contentId}${isTeamsPage ? location.search : ""}`;
  const isUserLoggedIn = userProfile?.isLoggedIn ? true : false;
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );
  const lookbackProgram = useMemo(() => {
    return (
      (isLookbackEnabled &&
        getNewestLookbackProgram(schedules, subscribedChannels, isInHome, appProvider.channelMapID)) ||
      null
    );
  }, [isInHome, isLookbackEnabled, schedules, subscribedChannels, appProvider]);
  const lookbackHoursLeft = useMemo(
    () => (lookbackProgram ? calculateLookbackHoursLeft(lookbackProgram) : 0),
    [lookbackProgram]
  );

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  const pcLevel = useMemo(
    () => getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled),
    [isParentalPINEnabled, isUserProfilesEnabled, userProfile]
  );

  /**
   * Triggers a recording analytics event based on a toast message passed
   * @param {String} toastMsg
   */
  const triggerRecordingAnalyticsEvent = useCallback(
    (toastMsg) => {
      if (recordingEventTypeRef.current) {
        if (toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_SET)) {
          trackGenericAction(recordingEventTypeRef.current, {
            isItemLive: true,
            isSeriesEvent: true,
            ...mapRecordingSeriesMediaContent(selectedAssetRecordingInfo?.assetToRecord),
          });
        } else if (toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_UPDATED)) {
          recordingEventCallbackFuncRef.current();
        } else {
          trackRecordingError(recordingEventTypeRef.current, toastMsg, {
            errorCode: setRecordingResponse?.code,
            errorMessage: setRecordingResponse?.message,
          });
        }
      }
    },
    [selectedAssetRecordingInfo, setRecordingResponse, translate]
  );

  /**
   * A callback function which will be triggered after a recording has been updated
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} mappedContentType live|svod|tvod|cpvr|lpvr
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({ isItemLive, isSeriesEvent, mappedContentType, analyticsEventType, toolSelections, error }) => {
      if (error) {
        // Triggers a stop|delete recording error.
        // The error of start a recording event comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        recordingEventCallbackFuncRef.current = () =>
          trackGenericAction(analyticsEventType, {
            isItemLive,
            isSeriesEvent,
            toolSelections,
            mappedContentType,
            ...mapRecordingSeriesMediaContent(selectedAssetRecordingInfo?.assetToRecord),
          });

        // The result of stop|delete a recording is ready at this moment
        if (analyticsEventType !== ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE) {
          recordingEventCallbackFuncRef.current();
        } else {
          // At this moment, the result of editing a recording is unknown, but a toastMessage will show up on the screen according to the result of editing a recording.
          // Thus set the recordingEventType here, and then trigger the event based on the toastMessage later.
          recordingEventTypeRef.current = analyticsEventType;
        }
      }
    },
    [selectedAssetRecordingInfo]
  );

  useEffect(() => {
    if (priorityEpisode) convivaContSubType.current.updateContSubTypeViaPriorityEpisode(priorityEpisode);
  }, [priorityEpisode]);

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    resetAction(SET_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetEditRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
  ]);

  useEffect(() => {
    return () => {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.SERIES_NAME, null);
      setCurrentSeasonIndex(null);
      setPriorityEpisode(null);
      setIsItemLive(false);
      liveItem.current = 0;
      setViewAllUrl("");
      resetAction(LOAD_SERIES_PAGE_CONTAINERS, "content");
      resetAction(LOAD_SERIES_DETAIL, "content");
      resetAction(LOAD_SIMILAR_SERIES, "content");
      resetAction(ADD_FAVOURITE, "content");
      resetAction(DELETE_FAVOURITE, "content");
      resetAction(LOAD_SERIES_SCHEDULES, "content");
      resetAction(LOAD_CONTENT_USERDATA, "content");
      resetAction(LOAD_SEASON_DATA, "content");
      resetAction(LOAD_SEASON_ENTITLEMENTS, "content");
      resetAction(LOAD_LIST_OF_TEAMS, "content");
      resetAction(LOAD_RECENT_AIRINGS, "content");
      resetAction(LOAD_LIST_OF_GAMES, "content");
      areGenreBasedSimilarItemsFetched.current = false; // re-setting on change of content
      resetRecordingActionValues();
    };
  }, [contentId, resetAction, resetRecordingActionValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTVShowDetailItems(appProvider, detailUri, CONTAINER_TYPES.CONTAINERS);
  }, [appProvider, detailUri, loadTVShowDetailItems]);

  useEffect(() => {
    if (contentMetadata) {
      if (userProfile?.isLoggedIn) {
        const seasons = isUnifiedAsset ? contentMetadata.containers : null;
        isUnifiedAsset && loadSeasons(seasons);
        if (!isUnifiedAsset) {
          loadSchedules(appProvider, contentMetadata.metadata?.title, cancelTokenSource);
        }
      }

      if (!isRecommendationMLTEnabled && contentMetadata.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
        loadSimilarItems(
          appProvider,
          contentMetadata.metadata.contentType?.toLowerCase(),
          contentMetadata.metadata.extendedMetadata.dlum.displayGenres[0],
          contentMetadata.metadata.contentSubtype || CONTENT_ITEM_TYPES.series,
          userProfile,
          pcLevel,
          cancelTokenSource
        );
      }

      // TODO: Check if we need this call for teams page while working on live game ticket(TCDWC-3865)
      !isTeamsPage &&
        loadContentUserData(
          appProvider,
          isSportSeries ? contentMetadata.metadata?.seriesUaId : contentMetadata.id,
          PAGE_CONTENT_ITEM_TYPES.tvShow
        );
    }
  }, [
    appProvider,
    cancelTokenSource,
    isRecommendationMLTEnabled,
    isUnifiedAsset,
    loadContentUserData,
    loadSchedules,
    loadSeasons,
    loadSimilarItems,
    pcLevel,
    contentMetadata,
    userProfile,
    isTeamsPage,
    isSportSeries,
  ]);

  useEffect(() => {
    if (appProvider && contentMetadata?.metadata) {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.SERIES_NAME, contentMetadata.metadata.title);

      const assetDetails = { ...contentMetadata };

      // Add TAMS ID from priority episode
      if (assetDetails.metadata && !assetDetails.metadata.externalId && priorityEpisode) {
        assetDetails.metadata.externalId = priorityEpisode.metadata?.externalId;
      }
      const priorityLiveProgram = lookbackProgram || liveSchedule;

      trackPageView({
        pageName: contentMetadata.metadata.title,
        contentId: contentMetadata.metadata.contentId,
        productId: contentMetadata.metadata.extendedMetadata?.dlum?.umId,
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.MEDIA_CONTENT,
            asset: {
              isItemLive,
              ...assetDetails,
              channel: {
                channelId: priorityLiveProgram?.channel?.channelId,
                channelNumber: getRegionalChannelNumber(priorityLiveProgram?.channel, appProvider?.channelMapID),
              },
              mappedContentType: priorityEpisode ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
            },
          },
          { type: EXTRA_METADATA_TYPES.COMMERCE },
          ...(getSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM)
            ? [{ type: EXTRA_METADATA_TYPES.SEARCH_CLICK }]
            : []),
        ],
      });
    }
  }, [trackPageView, contentMetadata, liveSchedule, appProvider, priorityEpisode, isItemLive, lookbackProgram]);

  useEffect(() => {
    return () => {
      resetIsPageViewTracked();
    };
  }, [contentMetadata, resetIsPageViewTracked]);

  useEffect(() => {
    if (similarItems && contentMetadata?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
      setViewAllUrl(
        `${SIMILAR_ITEMS.route}/${contentMetadata.id}` +
          `?itemType=${CONTENT_ITEM_TYPES.series}` +
          `&genres=${contentMetadata.metadata.extendedMetadata.dlum.displayGenres[0]}` +
          `&itemSubType=${contentMetadata.metadata.contentSubtype ?? CONTENT_ITEM_TYPES.series}`
      );
    }
    if (
      contentMetadata?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0 &&
      !areGenreBasedSimilarItemsFetched.current && // check to make api call only if not made before, to avoid loop in case of genre-based api failure
      isRecommendationMLTEnabled &&
      similarItems?.length === 0 // similarItems is an empty array in case of api failure, so this checks if the recommendation API failed or returned empty
    ) {
      areGenreBasedSimilarItemsFetched.current = true;
      loadSimilarItems(
        appProvider,
        contentMetadata.metadata?.contentType?.toLowerCase(),
        contentMetadata?.metadata?.extendedMetadata?.dlum?.displayGenres[0],
        contentMetadata.metadata?.contentSubtype || CONTENT_ITEM_TYPES.series,
        userProfile,
        pcLevel,
        cancelTokenSource
      );
    }
  }, [
    contentMetadata,
    similarItems,
    isRecommendationMLTEnabled,
    loadSimilarItems,
    appProvider,
    pcLevel,
    cancelTokenSource,
    userProfile,
  ]);

  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      if (!subscribedChannels && appProvider)
        loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled);
    }
  }, [
    appProvider,
    cancelTokenSource,
    loadUserSubscribedChannels,
    subscribedChannels,
    userProfile,
    isUserProfilesEnabled,
  ]);

  const currentAndUpcomingSchedules = useMemo(() => {
    if (!isTeamsPage && schedules?.length) {
      const currentTime = moment().valueOf();
      return schedules.filter((schedule) => {
        return schedule.metadata.airingEndTime >= currentTime;
      });
    }
  }, [schedules, isTeamsPage]);

  /** we need to create a recordable program list from live and upcoming schedules, this list can be used on recording functions */
  const recordablePrograms = useMemo(() => {
    if (isRecordingEnabled && currentAndUpcomingSchedules && subscribedChannels) {
      return getRecordablePrograms(currentAndUpcomingSchedules, subscribedChannels);
    }
  }, [isRecordingEnabled, currentAndUpcomingSchedules, subscribedChannels]);

  // list of recording info objects for every program schedule that the user is subscribed to and is airing on a recordable channel
  const programsRecordingInfoList = useMemo(() => {
    if (recordingsList && recordablePrograms) {
      return recordablePrograms.map((schedule) => {
        return getRecordingInfo(isMR, schedule, recordingsList, appProvider?.channelMapID);
      });
    }

    return null;
  }, [recordingsList, isMR, recordablePrograms, appProvider]);

  useEffect(() => {
    if (!recordingsList) return;
    const filterContainers = (recording) => {
      return recording.containers.filter((container) => {
        const extendedMetadata = container?.metadata?.extendedMetadata?.dlum;
        const recordingStatus = getCPVRRecordingStatus({ eventRecordingItem: container });

        if (!(isCPVRRecordingRecorded(recordingStatus) || isCPVRRecordingInProgress(recordingStatus))) {
          return false;
        }

        if (isSportSeries) {
          return extendedMetadata?.uaGroupId === contentMetadata?.metadata?.seriesUaId;
        }
        if (isTeamsPage) {
          const sports = extendedMetadata?.sports;
          return (
            sports?.homeUmId === contentMetadata?.metadata?.teamUmId ||
            sports?.guestUmId === contentMetadata?.metadata?.teamUmId
          );
        }
        return false;
      });
    };

    const filtered = recordingsList.flatMap(filterContainers);

    const sortedFiltered = filtered.sort((a, b) => {
      const timeA = new Date(a?.metadata?.recordingStartTime).getTime();
      const timeB = new Date(b?.metadata?.recordingStartTime).getTime();
      return timeB - timeA;
    });

    setFilteredRecordings(sortedFiltered);
  }, [recordingsList, contentMetadata, isSportSeries, isTeamsPage, setFilteredRecordings]);

  const getRecentRecordingItems = () => {
    const title = translate("recent_recordings");
    return createSwimlaneModels({
      data: filteredRecordings.length > 20 ? filteredRecordings.slice(0, 20) : filteredRecordings,
      avsComponent: { metadata: { label: title } },
      addParams: {
        DIMENSIONS: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS,
        viewAllUrl: filteredRecordings.length > 20 ? getRouteToPVRManager() : null,
      },
      itemsCount: filteredRecordings.length,
      isAppLanguageFrench,
      userProfile,
    });
  };

  // Recording info that is associated with the primary recording button
  const primaryRecordingInfo = useMemo(() => {
    if (programsRecordingInfoList) {
      if (isMR) {
        return programsRecordingInfoList.find((program) => !program.recordingEventScheduledCheck);
      } else {
        // Find the first schedule that has a recording associated with it already
        const firstExistingRecordingAsset = programsRecordingInfoList.find((recordingInfo) => {
          const assetRecordingStatus = getCPVRRecordingStatus(recordingInfo, true);
          return (
            !doesCPVREventRecordingExist(assetRecordingStatus) && doesCPVRSeriesRecordingExist(assetRecordingStatus)
          );
        });

        return firstExistingRecordingAsset || programsRecordingInfoList[0];
      }
    }

    return null;
  }, [isMR, programsRecordingInfoList]);

  // Primary recording button CTA details
  const primaryRecordingCTA = useMemo(() => {
    if (primaryRecordingInfo) {
      if (isMR) {
        let isSeriesRecordingSet = false;
        if (primaryRecordingInfo.recordingSeriesScheduledCheck) {
          isSeriesRecordingSet = true;
        }

        return getRecordingCTAs(
          true,
          primaryRecordingInfo,
          recordablePrograms,
          false,
          false,
          primaryRecordingInfo.recordingSeriesConflictCheck,
          isSeriesRecordingSet,
          true
        );
      } else {
        return getRecordingCTAs(false, primaryRecordingInfo, recordablePrograms, false, false, false, false, true);
      }
    }
    return null;
  }, [isMR, primaryRecordingInfo, recordablePrograms]);

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType) => {
      let toastMsg;
      if (recordingInfo.code) {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
        } else if (actionType === "edit") {
          toastMsg = translate(RECORDING_PARAMS["SERIES_RECORDING_NOT_UPDATED"]);
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS["SERIES_RECORDING_SET"]);
        } else if (actionType === "edit") {
          toastMsg = translate(RECORDING_PARAMS["SERIES_RECORDING_UPDATED"]);
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS["SERIES_RECORDING_CANCELLED"]);
        }
      }
      triggerRecordingAnalyticsEvent(toastMsg);

      return toastMsg;
    },
    [translate, triggerRecordingAnalyticsEvent]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(recordingInfo, manipulatedRecordingParams.actionType);
        if (toastMsg) {
          showToastNotification(toastMsg);
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, showToastNotification]
  );

  // Used to display the loading spinner on the primary recording button, as appropriate
  const showRecordingCTALoading =
    manipulatedRecordingParams &&
    primaryRecordingInfo &&
    manipulatedRecordingParams.targetAssetInfo.id === primaryRecordingInfo.assetToRecord.id;

  /**
   * Fetch the user's recordings and save the response in redux
   *
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(
          appProvider,
          true,
          null,
          null,
          null,
          MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
          recordingParamsMR
        );
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR]
  );

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/edit/delete recording action.
   */
  useEffect(() => {
    if (programsRecordingInfoList && manipulatedRecordingParams && isRecordingActionInProgress.current) {
      const selectedRecordingInfo = programsRecordingInfoList.find((recordingInfo) => {
        return recordingInfo.assetToRecord.id === selectedAssetRecordingInfo.assetToRecord.id;
      });
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (isMR && selectedRecordingInfo.recordingSeriesConflictCheck) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }

      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    programsRecordingInfoList,
    isMR,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    selectedAssetRecordingInfo,
  ]);

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              MODAL_TYPES.ERROR,
              showModalPopup
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [setRecordingResponse, isMR, fetchRecordings, showModalPopup, resetRecordingActionValues]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (SERIES)
   * @param {Object} recordingInfo - recording information used to set the recording
   */
  const scheduleRecording = useCallback(
    (typeOfRecording, recordingInfo) => {
      if (recordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, setRecordingAction]
  );

  /**
   * Displays the recording editing panel when the user tries to edit the recordings
   *
   * @param {Object} recordingInfo - recording information used to edit the recording
   * @param {String} typeOfRecording - the recording type (SERIES)
   */
  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      trackRecordingUpdateEventCallback({
        isItemLive: true,
        isSeriesEvent: true,
        mappedContentType: MAPPED_CONTENT_TYPES.LIVE,
        analyticsEventType: ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START,
      });
      showRecordingSettingsPanel(
        typeOfRecording,
        recordingInfo,
        !isMR ? recordablePrograms : null,
        false,
        trackRecordingUpdateEventCallback
      );
    },
    [recordablePrograms, showRecordingSettingsPanel, trackRecordingUpdateEventCallback, isMR]
  );

  /** Click event handler replaces the recording modal popup*/
  const handleRecordingAction = useCallback(
    (event, recordingInfo) => {
      event.stopPropagation();
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        if (isMR) {
          const hasSeriesRecording = recordingInfo.recordingSeriesScheduledCheck;
          const hasConflict = recordingInfo.recordingSeriesConflictCheck;
          if (hasConflict) {
            setShowRecordingModal(true);
          } else if (hasSeriesRecording) {
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.SERIES);
            toggleSettingsPanelAction(true);
          } else if (!recordingInfo.recordingSeriesConflictCheck && !recordingInfo.recordingSeriesScheduledCheck) {
            scheduleRecording(RECORDING_PARAMS.SERIES, recordingInfo);
          }
        } else {
          const cpvrRecordingExists = doesCPVRSeriesRecordingExist(getCPVRRecordingStatus(recordingInfo, true));
          if (cpvrRecordingExists) {
            // Show edit settings panel
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.SERIES);
            toggleSettingsPanelAction(true);
          } else {
            scheduleRecording(RECORDING_PARAMS.SERIES, recordingInfo);
          }
        }
      }
    },
    [isMR, showRecordingSettingHandler, toggleSettingsPanelAction, scheduleRecording]
  );

  useEffect(() => {
    const fetchFirstPlayableEpisode = async (season) => {
      // Need to display the fist season's episode and function sorts in descending order
      const seasons = [...seasonData]?.sort(seasonCompareFunction);
      const firstSeason = seasons?.[seasons.length - 1];
      const isEntitlementCallNeeded = getCheckForEntitlementCall(season, firstSeason);

      const firstPlayableEpisodeInfo =
        seasonEntitlements &&
        (await getFirstPlayableEpisode(appProvider, firstSeason, seasonEntitlements, isEntitlementCallNeeded));
      if (firstPlayableEpisodeInfo) {
        if (Array.isArray(firstPlayableEpisodeInfo)) {
          if (
            firstPlayableEpisodeInfo.length > 1 &&
            firstPlayableEpisodeInfo.every((episodeInfo) => !(episodeInfo.userData?.metadata?.bookmarks?.length > 0))
          ) {
            setSvodWatchOptions(firstPlayableEpisodeInfo); // displaying ways to watch prompt, in case multiple svod watch options are present and series is un-started
          }
          setPriorityEpisode(firstPlayableEpisodeInfo[0]); // giving priority to first available svod watch option
        } else {
          setPriorityEpisode(firstPlayableEpisodeInfo);
        }
      }
    };

    const fetchPlayableEpisode = async (bookmark, season, isEntitlementCallNeeded) => {
      const playableEpisodeInfo =
        seasonEntitlements &&
        (await getPlayableEpisode(appProvider, bookmark, season, seasonEntitlements, isEntitlementCallNeeded));
      if (!isSportSeries) {
        if (playableEpisodeInfo) {
          setPriorityEpisode(playableEpisodeInfo);
        } else {
          fetchFirstPlayableEpisode(season);
        }
      }
    };
    if ((contentUserData || appProvider.panicMode) && seasonData?.length > 0) {
      const bookmarks = contentUserData?.metadata?.bookmarks;
      const sortedBookmarks = bookmarks?.sort((previousBookmark, nextBookmark) => {
        return (
          parseInt(nextBookmark?.season) - parseInt(previousBookmark?.season) ||
          parseInt(nextBookmark?.episodeNumber) - parseInt(previousBookmark?.episodeNumber)
        );
      });
      const sortedSeasonData = seasonData.sort(seasonCompareFunction);
      const seasonDataSort = getSortedSeasons(contentMetadata, sortedSeasonData);
      let season;
      if (seasonIndex) {
        season = seasonDataSort[seasonIndex];
      } else {
        if (sortedBookmarks?.length > 0) {
          season = seasonDataSort.find((season) => {
            return parseInt(season?.metadata?.season) === parseInt(sortedBookmarks[0].season);
          });
        } else {
          season = seasonDataSort?.[seasonDataSort.length - 1];
        }
      }
      setCurrentSeasonIndex(seasonDataSort.findIndex((seasonData) => seasonData === season));
      setEntitlementSeason(season);

      if (!priorityEpisode) {
        const lastBookmark =
          sortedBookmarks?.find((bookmark) => {
            return parseInt(bookmark?.season) === parseInt(season?.metadata?.season);
          }) || sortedBookmarks?.[0];
        if (!isSportSeries) {
          if (lastBookmark) {
            const bookmarkedSeason = seasonDataSort.find((season) => {
              return parseInt(season?.metadata?.season) === parseInt(lastBookmark.season);
            });
            fetchPlayableEpisode(lastBookmark, bookmarkedSeason, getCheckForEntitlementCall(season, bookmarkedSeason));
          } else {
            fetchFirstPlayableEpisode(season);
          }
        }
      }
    }
    if (isSportSeries) {
      setCurrentSeasonIndex(seasonIndex ?? 0);
    }
  }, [
    seasonData,
    isSportSeries,
    contentUserData,
    appProvider,
    seasonIndex,
    isUnifiedAsset,
    loadTVShowDetailItems,
    seasonEntitlements,
    priorityEpisode,
    contentMetadata,
  ]);

  useEffect(() => {
    if (entitlementSeason) {
      loadSeasonEntitlements(appProvider, `${entitlementSeason.id}?season=${entitlementSeason.metadata?.season}`);
    }

    return () => {
      resetAction(LOAD_SEASON_ENTITLEMENTS, "content");
    };
  }, [appProvider, loadSeasonEntitlements, resetAction, entitlementSeason]);

  const firstLiveOOHAsset = useRef(null);
  const contentMetadataId = contentMetadata?.id;

  useEffect(() => {
    const processScheduleItem = (currentTime, liveSchedules, nextIndex) => {
      const processNextScheduleItem = () => {
        scheduleItemIndex.current++;
        if (scheduleItemIndex.current < liveSchedules.length) {
          processScheduleItem(currentTime, liveSchedules, scheduleItemIndex.current);
        }
      };

      const schedule = liveSchedules[nextIndex];
      if (schedule?.metadata && schedule.channel) {
        const channelObj = getLiveChannelAsset(subscribedChannels?.containers, schedule.channel.channelId);
        const isLive =
          currentTime > schedule.metadata.airingStartTime &&
          currentTime < schedule.metadata.airingEndTime &&
          !isGhostChannel(channelObj);
        if (isLive) {
          !isItemLive && setIsItemLive(true);
          if (!channelObj?.metadata?.isNotAvailableOutOfHome) {
            // Checking first for in-home live schedule
            if (!liveItem.current) {
              liveItem.current = schedule;
            } else {
              processNextScheduleItem();
            }
          } else {
            if (!firstLiveOOHAsset.current) {
              firstLiveOOHAsset.current = schedule;
            }
            if (
              scheduleItemIndex.current === liveSchedules.length - 1 &&
              firstLiveOOHAsset.current &&
              !liveItem.current
            ) {
              liveItem.current = firstLiveOOHAsset.current; // Using the first out-of-home live schedule in case no in-home live schedules are present for live CTA
              isLiveProgramOOH.current = true;
            }
          }
          setLiveSchedule(schedule);
        } else {
          processNextScheduleItem();
        }
      } else {
        processNextScheduleItem();
      }
    };

    if (schedules?.length && contentMetadataId && !isItemLive) {
      const currentTime = moment().valueOf();
      // Filtering in the schedules which are currently live.
      const liveSchedules = schedules.filter(
        (schedule) => currentTime < schedule.metadata.airingEndTime && currentTime > schedule.metadata.airingStartTime
      );
      if (liveSchedules.length) {
        if (isUnifiedAsset) {
          processScheduleItem(currentTime, liveSchedules, scheduleItemIndex.current); // To be disable live CTA button for MVE1
        } else {
          const liveItemDetail = getItemLiveAvailability(liveSchedules, contentMetadataId, appProvider, currentTime);
          if (liveItemDetail) {
            setIsItemLive(true);
            if (!liveItem.current) {
              liveItem.current = liveItemDetail;
              if (liveItemDetail.isNotAvailableOutOfHome) isLiveProgramOOH.current = true;
            }
          }
        }
      }
    }
  }, [schedules, isUnifiedAsset, contentMetadataId, appProvider, isItemLive, subscribedChannels]);

  /**
   * Load channels data if it doesn't exist yet & user is logged in
   */
  useEffect(() => {
    if (isUserLoggedIn && !channelMapInfo && appProvider) {
      loadChannels(appProvider, cancelTokenSource);
    }
  }, [appProvider, channelMapInfo, isUserLoggedIn, loadChannels, cancelTokenSource]);

  const getEpisodeList = () => {
    let episodeItems = [];
    if ((schedules && subscribedChannels?.containers) || listOfLiveGames) {
      episodeItems = createEpisodeSwimlaneItems(
        liveUAEpisodes,
        schedules,
        subscribedChannels,
        priorityEpisode?.userData?.entitlement?.isGeoBlocked,
        isInHome,
        isLookbackEnabled,
        appProvider.channelMapID,
        listOfLiveGames
      );
    }

    return createSwimlaneFeedItem("", episodeItems);
  };

  const getRolesFeed = () => {
    const peopleList = contentMetadata.metadata.extendedMetadata.dlum.people;
    const title = translate(SWIMLANE_TITLES.CAST_AND_CREW);
    return createSwimlaneModels({
      data: peopleList.map((item) => ({ metadata: item })),
      avsComponent: { metadata: { label: title }, title },
      addParams: { DIMENSIONS: ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS },
      itemsCount: peopleList.length,
      isAppLanguageFrench,
      userProfile,
    });
  };

  const addFavourite = () => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      trackGenericAction(ANALYTICS_EVENT_TYPES.FAVOURITE, {
        ...contentMetadata,
        mappedContentType: priorityEpisode ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
      });
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.FAVOURITE,
          getCustomContextMetadata(contentMetadata, userProfile, appProvider, isInHome)
        );
      }
      addFavourites(appProvider, contentMetadata, PAGE_CONTENT_ITEM_TYPES.tvShow, isSportSeries);
    }
  };

  const removeFavourite = () => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      trackGenericAction(ANALYTICS_EVENT_TYPES.UNFAVOURITE, {
        ...contentMetadata,
        mappedContentType: priorityEpisode ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
      });
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.UNFAVOURITE,
          getCustomContextMetadata(contentMetadata, userProfile, appProvider, isInHome)
        );
      }
      deleteFavourites(appProvider, contentMetadata, PAGE_CONTENT_ITEM_TYPES.tvShow);
    }
  };

  const closeRecordingModal = () => {
    document.body.style.overflowY = "scroll";
    setShowRecordingModal(false);
  };

  const getRecordingButton = useCallback(() => {
    if (primaryRecordingCTA) {
      return (
        <ImageButton
          src={primaryRecordingCTA.recordingIcon}
          className="wall-content-icon wall-recording-icon"
          onClickHandler={(event) => handleRecordingAction(event, primaryRecordingInfo)}
          testID="wallRecordIcon"
          buttonContainerStyles="button-container"
          alt={primaryRecordingCTA.altHead}
          tooltipDirection="top"
        />
      );
    }
  }, [handleRecordingAction, primaryRecordingCTA, primaryRecordingInfo]);

  /**
   * @param {Object} recordingInfo - Object containing response of particular asset from recordingsList object to compare the recording.
   * @param {String} typeOfRecording - SERIES
   */
  const editRecordingHandler = (recordingInfo, typeOfRecording) => {
    showRecordingSettingHandler(recordingInfo, typeOfRecording);
  };

  const onSeasonSelected = (item) => {
    if (item) {
      if (isSportSeries) {
        setCurrentSeasonIndex(
          modifiedGamesList?.findIndex((liveGame) => liveGame.metadata?.season === item.metadata?.season)
        );
      } else {
        loadSeasonEntitlements(appProvider, `${item.id}?season=${item.metadata?.season}`);
        logNREvent(NR_PAGE_ACTIONS.SEASON_SELECT, {
          seasonName: item.metadata?.season,
          seriesId: item.id,
        });
        resetAction(LOAD_SEASON_ENTITLEMENTS, "content");
      }
    }
  };

  const getFavouriteButton = () => {
    return !isTeamsPage && isFavouritesEnabled && contentUserData?.metadata ? (
      <ImageButton
        src={isItemFavourite ? favourite_icon_active : favourite_icon}
        className="wall-content-icon"
        onClickHandler={isItemFavourite ? removeFavourite : addFavourite}
        alt={isItemFavourite ? translate("action_unfavourite") : translate("action_favourite")}
        tooltipDirection="top"
      />
    ) : null;
  };

  const getSeriesInfoButton = () => {
    return isTeamsPage && contentMetadata.metadata.seriesUaId ? (
      <ImageButton
        src={seriesInfoIcon}
        className="wall-content-icon"
        onClickHandler={() =>
          (window.location.href = `#${SERIES_DETAIL_PAGE.route}/PAGE/DETAILS/${PAGE_CONTENT_ITEM_TYPES.sportsSeries}/${contentMetadata.metadata.seriesUaId}`)
        }
        alt={translate("series_info")}
        tooltipDirection="top"
      />
    ) : null;
  };

  const getTeamItems = () => {
    const title = translate(SWIMLANE_TITLES.TEAMS);
    return createSwimlaneModels({
      data: listOfTeams.length > 20 ? listOfTeams.slice(0, 20) : listOfTeams,
      avsComponent: { metadata: { label: title }, title },
      addParams: {
        DIMENSIONS: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS,
        viewAllUrl: `/viewall/TRAY/SEARCH/TEAMS?filter_uaSeriesId=${contentMetadata.metadata.seriesUaId}`,
      },
      itemsCount: listOfTeams.length,
      isAppLanguageFrench,
      userProfile,
    });
  };

  const getSimilarItems = () => {
    if (similarItems?.length > 0) {
      // Filtering out any items that point to the detail page we are currently on
      const filteredSimilarItems = similarItems.filter(
        (item) =>
          item?.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri !== detailUri
      );

      if (filteredSimilarItems?.length > 0) {
        const title = translate(SWIMLANE_TITLES.SIMILAR_ITEMS);
        return createSwimlaneModels({
          data: filteredSimilarItems,
          avsComponent: { metadata: { label: title }, title },
          addParams: { DIMENSIONS: ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS, viewAllUrl },
          itemsCount: totalSimilarItems || filteredSimilarItems.length,
          isAppLanguageFrench,
          userProfile,
        });
      }
    }

    return null;
  };

  const getRecentAirings = () => {
    // Filtering out any items that point to the detail page we are currently on
    const filteredRecentAirings = recentAirings.filter(
      (item) => item?.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri !== detailUri
    );

    if (filteredRecentAirings?.length > 0) {
      const title = translate(SWIMLANE_TITLES.RECENT_AIRINGS);
      return createSwimlaneModels({
        data: filteredRecentAirings,
        avsComponent: { metadata: { label: title }, title },
        addParams: {
          DIMENSIONS: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS,
          viewAllUrl: `/viewall/TRAY/SEARCH/VOD?filter_uaSeriesId=${contentMetadata.metadata.seriesUaId}${
            isTeamsPage ? `&filter_teamUmId=${contentMetadata.metadata.teamUmId}` : ""
          }&filter_contentType=VOD&title=${translate("recent_airings")}`,
        },
        itemsCount: filteredRecentAirings.length,
        isAppLanguageFrench,
        userProfile,
      });
    }

    return null;
  };

  let isItemFavourite = useMemo(() => contentUserData?.metadata?.favorites?.[0], [contentUserData]);
  isAPIcallFired.current = false;
  if (isAddedToFavourites !== null) {
    isItemFavourite = isAddedToFavourites;
  }

  const toastClickHandler = (type) => {
    if (type === "outHome") {
      showToastNotification(translate("message_in_home"), outHomeIcon);
    } else if (type === "geoblock") {
      showToastNotification(translate("error_program_not_available_region"));
    } else if (type === "browser") {
      showToastNotification(translate("error_browser_restriction"), null, {
        media: { isItemLive: false, ...contentMetadata },
      });
    } else if (type === "subscribe") {
      showToastNotification(translate("not_subscribed_restriction"), unSubscribedIcon, {
        media: { isItemLive: false, ...contentMetadata },
      });
    } else {
      return false;
    }
  };

  const onPlayClick = (type, priorityEpisode) => {
    if (toastClickHandler(type) === false) {
      if (svodWatchOptions?.length > 1) {
        setShowWaysToWatch(true);
      } else {
        setSessionStorage(
          ANALYTICS_STORAGE_KEYS.LINK,
          `${LINK_INFO.ON_DEMAND_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
        );
        performPlaybackChecks(
          priorityEpisode.userData,
          PLAYBACK_TYPES.EPISODE,
          priorityEpisode.metadata?.contentId,
          PAGE_CONTENT_ITEM_TYPES.vod,
          ON_DEMAND_PLAYER.route
        );
      }
    }
  };
  const getEntitlementType = (entitlement, episodeType) => {
    let label,
      icon,
      className = "",
      type;
    if (entitlement?.isChannelNotSubscribed) {
      label = "available_tv_mobile";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (
      (entitlement?.isContentOOHBlocked || (isItemLive && !lookbackProgram && isLiveProgramOOH.current)) &&
      !isInHome
    ) {
      label = "action_ooh";
      icon = outHomeIcon;
      className = "restricted";
      type = "outHome";
    } else if (priorityEpisode?.userData?.entitlement?.isPlatformBlacklisted) {
      label = "";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (
      entitlement?.isGeoBlocked // MVE1 feature
    ) {
      label = episodeType === "play" ? "available_tv_mobile" : "action_live";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (entitlement?.isSportBlackoutBlocked) {
      label = "";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (episodeType === "play") {
      let labelType;
      if (priorityEpisode.userData?.metadata?.bookmarks?.length > 0) {
        labelType = "resume";
      } else {
        labelType = "play";
      }
      const uaEpisodeObject = priorityEpisode.metadata && getAutoGeneratedObject(priorityEpisode.metadata);
      if (uaEpisodeObject.isSeasonAutoGenerated || uaEpisodeObject.isEpisodeAutoGenerated) {
        label = translate(`${labelType}_on_demand`);
      } else {
        label =
          priorityEpisode.metadata && priorityEpisode.metadata?.season
            ? priorityEpisode.metadata?.episodeNumber
              ? `${translate(labelType)} S${priorityEpisode.metadata?.season} E${
                  priorityEpisode.metadata?.episodeNumber
                } ${translate("on_demand")}`
              : `${translate(labelType)} S${priorityEpisode.metadata?.season} ${translate("on_demand")}`
            : priorityEpisode.metadata?.episodeNumber
            ? `${translate(labelType)} E${priorityEpisode.metadata?.episodeNumber} ${translate("on_demand")}`
            : translate(`${labelType}_on_demand`);
      }
      icon = playIcon;
      type = "play";
    } else if (lookbackProgram) {
      label = "restart_live_tv";
      icon = playIcon;
      className = "play";
    } else if (episodeType === "live") {
      label = "action_live";
      icon = playIcon;
      type = "live";
    } else if (entitlement?.isGeofencedBlocked) {
      label = "action_live";
      icon = playIcon;
      className = "play";
      type = "geofence";
    }
    return {
      label: label,
      icon: icon,
      className: className,
      type: type,
    };
  };

  const getPlayButton = () => {
    const entitlement = priorityEpisode.userData?.entitlement;
    const entitlementObj = getEntitlementType(entitlement, "play");
    return (
      <OptikButton
        label={translate(entitlementObj?.label)}
        icon={entitlementObj?.icon}
        className={entitlementObj?.className}
        onClickHandler={() => onPlayClick(entitlementObj?.type, priorityEpisode)}
      />
    );
  };

  const getLiveButton = () => {
    const entitlementObj = getEntitlementType(null, "live");
    const label = `${translate(entitlementObj?.label)}`;
    return (
      <OptikButton
        label={label}
        icon={entitlementObj?.icon}
        className={entitlementObj?.className}
        onClickHandler={() => handleLiveItemClick(entitlementObj?.type)}
      />
    );
  };

  const pageTitle = contentMetadata ? contentMetadata.Name : "";
  const handleLiveItemClick = (type) => {
    setSessionStorage(
      ANALYTICS_STORAGE_KEYS.LINK,
      `${LINK_INFO.LIVE_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
    );
    if (toastClickHandler(type) === false) {
      performPlaybackChecks(
        null,
        null,
        lookbackProgram ? lookbackProgram.channel?.metadata?.channelId : liveItem.current?.channel?.channelId,
        PAGE_CONTENT_ITEM_TYPES.live,
        LIVE_PLAYER.route,
        null,
        null,
        lookbackProgram?.channel || null,
        null,
        lookbackProgram || null
      );
    }
  };

  const closeWaysToWatchPrompt = () => {
    setShowWaysToWatch(false);
  };

  const getSwimlaneRowNum = (feedTitle) => {
    const plusNum = (isSportSeries ? modifiedGamesList : seasonData)?.length ? 1 : 0;
    switch (feedTitle) {
      case SWIMLANE_TITLES.SIMILAR_ITEMS:
        return plusNum;
      case SWIMLANE_TITLES.CAST_AND_CREW:
        const swimlaneRowNum = similarItems ? 1 : 0;
        return swimlaneRowNum + plusNum;
      default:
        return null;
    }
  };

  const getLookbackAvailabilityElement = () => {
    return lookbackHoursLeft ? (
      <div className="lookback-string">{getLookbackAvailabilityString(lookbackHoursLeft)}</div>
    ) : null;
  };

  return loadComponent ? (
    <div className="detail-series">
      <SeoPageTags title={pageTitle} keywords={["optik", "telus"]} />
      {contentMetadata?.metadata && (
        <React.Fragment>
          <div className="series-wall-wrapper">
            <Wall
              cover={getAVSKeyArtImage(
                contentMetadata.metadata,
                IMAGES.ASPECT_RATIOS.DIM_9x16,
                isSportSeries || isTeamsPage
              )}
            />
            <div className="content-wrapper">
              <WallContent
                title={
                  isTeamsPage
                    ? contentMetadata.title
                    : contentMetadata.metadata.title || contentMetadata.metadata.seriesTitle
                }
                description={
                  isTeamsPage
                    ? contentMetadata.metadata.seriesTitle
                    : contentMetadata.metadata.longDescription || contentMetadata.metadata.seriesDescription
                }
                line1={!isSportSeries && !isTeamsPage && getSubTextLine1(contentMetadata, isAppLanguageFrench, true)}
                line2={getSubTextLine2(
                  priorityEpisode?.metadata || contentMetadata.metadata,
                  isAppLanguageFrench,
                  lookbackProgram?.metadata || liveSchedule?.metadata
                )}
                isShowMoreButton={true}
              >
                {!userProfile?.isLoggedIn && (
                  <div className="detail-page-cta login">
                    <OptikButton
                      label={translate("login")}
                      onClickHandler={() => {
                        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.LOGIN};${LINK_INFO.SUB_NAV}`);
                        handleLogin(appProvider, LOGIN_BRANDS.TELUS, isKoodoLoginEnabled);
                      }}
                    />
                  </div>
                )}
                {/* fixed UI issue for button placement of live play */}
                <div className="wall-children">
                  {priorityEpisode && !isSportSeries && !isTeamsPage ? (
                    <div className="detail-page-cta">{getPlayButton()}</div>
                  ) : null}
                  {(lookbackProgram || (isItemLive && liveItem.current !== 0)) && !isSportSeries && !isTeamsPage ? (
                    <div>
                      <div className="detail-page-cta live-btn">{getLiveButton()}</div>
                      {getLookbackAvailabilityElement()}
                    </div>
                  ) : null}
                  {primaryRecordingCTA ? (
                    <div className="recording-button-wrapper">
                      {getRecordingButton()}
                      {showRecordingCTALoading ? (
                        <img
                          className="record-page-loading"
                          src={process.env.PUBLIC_URL + "/images/Rec_Progress.svg"}
                          alt=""
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {getFavouriteButton()}
                  {getSeriesInfoButton()}
                </div>
              </WallContent>
            </div>
          </div>
          <div>
            {showWaysToWatch ? (
              <WaysToWatchPrompt
                watchOptions={svodWatchOptions}
                closePrompt={closeWaysToWatchPrompt}
                vodPlaybackType={CONTENT_ITEM_TYPES.episode}
              />
            ) : null}
            {((seasonData && seasonData.length > 0) || listOfLiveGames?.length > 0) && (
              <div className="season-detail">
                <div className="season-dropdown">
                  <SeasonPickerDropDown
                    onSeasonDropDownSelected={onSeasonSelected}
                    currentSeasonIndex={isTeamsPage ? 0 : currentSeasonIndex}
                    isTeamsPage={isTeamsPage}
                    isSportSeries={isSportSeries}
                    sportsContent={isSportSeries && modifiedGamesList}
                  />
                </div>
                <div>
                  {liveUAEpisodes?.length > 0 && (
                    <Swimlane
                      getSwimlaneRowNum={() => 0}
                      feed={getEpisodeList()}
                      dimensionConfig={ITEM_TYPES.TITLE_ITEM.EP_LIST_LANDSCAPE.DIMENSIONS}
                      sliderSettings={ITEM_TYPES.TITLE_ITEM.EP_LIST_LANDSCAPE.CONFIG}
                      ThumbnailComponent={EpisodeSwimlaneItem}
                      priorityEpisode={priorityEpisode}
                    />
                  )}
                </div>
              </div>
            )}
            {!isTeamsPage && listOfTeams?.length > 0 && (
              <div className="detail-swimlane">
                <Swimlane
                  feed={getTeamItems()}
                  dimensionConfig={ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS}
                  sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
                  ThumbnailComponent={SwimlaneItem}
                />
              </div>
            )}
            {(isTeamsPage || isSportSeries) && filteredRecordings?.length > 0 && (
              <div className="episode-swimlane">
                <Swimlane
                  feed={getRecentRecordingItems()}
                  dimensionConfig={ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS}
                  sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
                  ThumbnailComponent={SwimlaneItem}
                />
              </div>
            )}
            {(isTeamsPage || isSportSeries) && recentAirings?.length > 0 && (
              <div className="detail-swimlane">
                <Swimlane
                  feed={getRecentAirings()}
                  dimensionConfig={ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS}
                  sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
                  ThumbnailComponent={SwimlaneItem}
                />
              </div>
            )}
            {similarItems?.length > 0 && (
              <div className="detail-swimlane">
                <Swimlane
                  getSwimlaneRowNum={() => getSwimlaneRowNum(SWIMLANE_TITLES.SIMILAR_ITEMS)}
                  feed={getSimilarItems()}
                  dimensionConfig={ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS}
                  sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
                  ThumbnailComponent={SwimlaneItem}
                />
              </div>
            )}

            {contentMetadata.metadata.extendedMetadata?.dlum?.people?.length > 0 && (
              <div className="detail-swimlane cast">
                <Swimlane
                  getSwimlaneRowNum={() => getSwimlaneRowNum(SWIMLANE_TITLES.CAST_AND_CREW)}
                  feed={getRolesFeed()}
                  dimensionConfig={ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS}
                  sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
                  ThumbnailComponent={CastSwimlaneItem}
                />
              </div>
            )}
            {showRecordingModal && (
              <RecordingModal
                closeModal={closeRecordingModal}
                scheduleRecordingHandler={scheduleRecording}
                recordingInfo={selectedAssetRecordingInfo}
                editRecordingHandler={editRecordingHandler}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  ) : (
    <></>
  );
}

SeriesDetailPage.propTypes = {
  appProvider: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resetAction: PropTypes.func.isRequired,
  loadSimilarItems: PropTypes.func.isRequired,
  similarItems: PropTypes.array,
  featureToggles: PropTypes.object,
};

function mapStateToProps({ app, seriesDetails, feed, recording }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    feedItems: feed.content,
    similarItems: seriesDetails.similarItems,
    subscribedChannels: app.subscribedChannels,
    seasonData: seriesDetails.seasonDetails,
    firstEpisodeData: seriesDetails.firstEpisodeData,
    seasonEntitlements: seriesDetails.seasonEntitlements,
    recordingsList: recording.recordingsList,
    getRecordingError: recording.getRecordingError,
    setRecordingResponse: recording.setRecordingResponse,
    editRecordingResponse: recording.editRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    totalSimilarItems: seriesDetails.totalSimilarItems,
    isAddedToFavourites: app.isAddedToFavourites,
    schedules: seriesDetails.schedules,
    contentUserData: seriesDetails.contentUserData,
    isInHome: app.isInHome,
    featureToggles: app.featureToggles,
    channelMapInfo: app.channelMapInfo,
    listOfTeams: seriesDetails.listOfTeams,
    recentAirings: seriesDetails.recentAirings,
    listOfLiveGames: seriesDetails.listOfLiveGames,
  };
}

const mapDispatchToProps = {
  loadTVShowDetailItems,
  resetAction,
  loadSimilarItems,
  addFavourites,
  deleteFavourites,
  loadUserSubscribedChannels,
  showToastNotification,
  loadContentUserData,
  loadSeasons,
  loadSchedules,
  loadSeasonEntitlements,
  setRecordingAction,
  getRecordingAction,
  setConvivaContentSubType,
  showModalPopup,
  showRecordingSettingsPanel,
  toggleSettingsPanelAction,
  loadChannels,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesDetailPage);

/**
 * Method to compare two season objects with season number.
 * @param {Object} currentSeason
 * @param {Object} seasonToCompare
 * @returns {Boolean}
 */
const getCheckForEntitlementCall = (currentSeason, seasonToCompare) =>
  currentSeason?.metadata?.season !== seasonToCompare?.metadata?.season;
